<template>
  <div class="container">
    <van-card
      num="1"
      origin-price="12.00"
      price="10.00"
      desc="Goods descript"
      title="Goods Title"
      thumb="https://img01.yzcdn.cn/vant/ipad.jpeg"
    />
    <van-cell-group style="margin: 20px 0">
      <van-cell title="Goods Price" value="JPY 8" />
      <van-cell title="Shipping Price" value="JPY 1" />
      <van-cell title="Tax" value="JPY 1" />
      <van-cell title="Total" :value="`JPY ${orderPrice}`" />
      <!-- <template v-if="isCVS">
        <van-field
          v-model="form.name"
          label="Name"
          maxlength="20"
          input-align="right"
          placeholder="Please iput name"
        />
        <van-field
          v-model="form.surname"
          label="Surname"
          maxlength="20"
          input-align="right"
          placeholder="Please iput surname"
        />
        <van-field
          v-model="form.tel"
          label="Tel"
          type="tel"
          maxlength="11"
          input-align="right"
          placeholder="Please iput phone"
        />
        <van-field
          v-model="form.mail"
          label="Mail"
          maxlength="128"
          type="mail"
          input-align="right"
          placeholder="Please iput E-mail"
        />
      </template> -->
    </van-cell-group>

    <van-radio-group v-model="brandType">
      <van-cell-group title="Selection payment method">
        <van-cell title="" clickable @click="brandType = '02'">
          <template #title>
            <div class="flex">
              <img :src="wechatPay.logo" />
            </div>
          </template>
          <template #right-icon>
            <van-radio name="02" />
          </template>
        </van-cell>
        <van-cell title="" clickable @click="brandType = '91'">
          <template #title>
            <div class="flex">
              <img :src="ailpayLogo" />
            </div>
          </template>
          <template #right-icon>
            <van-radio name="91" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-radio-group>

    <van-submit-bar
      :price=orderPrice*100
      button-text="Submit"
      text-align="left"
      label="Total: "
      :loading="loading"
      @submit="handlePay"
    />
  </div>
</template>

<script>
import VueQr from 'vue-qr'
import { feqCocos } from '@/apis'
import logo from '@/assets/logo.svg'
import wechatImg from '@/assets/wechatpay.png'
import ailpayLogo from '@/assets/Alipay+.svg'

export default {
  name: 'Confirmation',
  data() {
    return {
      logo,
      visible: false,
      loading: false,
      brandType: '',
      presentmentMode: '',
      ailpayLogo,
      orderPrice: 10,
      brands: [],
      time: 10 * 60 * 1000,
      codeUrl: '',
      timer: null,
      form: {},
      wechatPay: {
        walletBrandName: 'WeChat Pay',
        value: 'wechat',
        logo: wechatImg,
      },
      merchantId: '82137',
      storeId: '001',
      promos: '',
    }
  },
  created() {
    this.initForm()
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    initForm() {
      // const { brandType, orderPrice, merchantId, storeId } = this
      const { brandType } = this
      const merchantId = this.$route.query.mid || '82137'
      const storeId = this.$route.query.sid || '001'
      const time = isNaN(this.$route.query.time) ? 10 : this.$route.query.time
      const orderPrice = isNaN(this.$route.query.price) ? 10 : this.$route.query.price
      this.orderPrice = orderPrice
      const expireTime = (time * 60).toString()
      const currency = 'JPY'
      const clientOrderNo = new Date().getTime()
      this.form = {
        brandType,
        cancelUrl: process.env.VUE_APP_BASE2 + '/#/m/cancel',
        // process.env.VUE_APP_BASE2 +
        // '/#/fep/m/result?clientOrderNo=' +
        // clientOrderNo,
        clientOrderNo,
        createAt: new Date().getTime(),
        currency,
        description: '',
        expireTime,
        merchantId,
        notifyUrl:
          'https://stgpscode.sbpayment.jp/api/pay/commonTestNotify/index',
        // notifyUrl:
        //     process.env.VUE_APP_BASE2 + '/#/fep/m/result?clientOrderNo=' +
        //   clientOrderNo,
        orderMeta: {
          products: [
            {
              id: '1',
              name: 'テスト 商品1',
              price: 8,
              quantity: 1,
            },
            {
              id: '2',
              name: 'テスト 商品2',
              price: 2,
              quantity: 1,
            },
          ],
        },
        orderPrice,
        payType: '06',
        redirectUrl: process.env.VUE_APP_BASE2 + '/#/m/pending?clientOrderNo=' + clientOrderNo,
        // process.env.VUE_APP_BASE2 +
        // '/#/fep/m/result?clientOrderNo=' +
        // clientOrderNo,
        storeId,
      }
    },
    async handlePay() {
      this.initForm()
      // if (!this.brandType) {
      //   this.$toast.fail('Please select a payment method')
      //   return
      // }
      feqCocos(this.form)
      this.loading = false
    },
  },
}
</script>
<style lang="less" scoped>
.container {
  padding-bottom: 60px;
}
.flex {
  display: flex;
  align-items: center;
  font-weight: 600;
}
.van-cell {
  align-items: center;
}
.van-cell-group img {
  margin-right: 10px;
  height: 30px;
  &.ailpay-logo {
    height: 20px;
  }
}
.van-submit-bar__button {
  width: auto;
  padding: 0 23px;
}
.logos {
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.van-submit-bar {
  background: #010101;
  color: #fff;
  .van-submit-bar__text {
    color: #fff;
  }
  .van-submit-bar__price {
    color: #f56723;
  }
  .van-button {
    background: #e24a45;
    border-radius: 0;
  }
}
</style>
